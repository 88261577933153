import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { ValidationError, useForm } from '@formspree/react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageContentWrapper } from '../templates/templates.style';
import { PostTitle } from '../components/post-details/post-details.style';
import { themeGet } from '@styled-system/theme-get';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    margin-bottom: 5px;
  }

  input, textarea {
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #cacaca;
    max-width: 500px;
  }

  textarea {
    height: 300px;
  }

  button {
    width: 200px;
    padding: 5px 12px;
    color: #fff;
    background-color: #292929;
    height: 44px;
    font-size: 15px;
    font-family: ${themeGet('fontFamily.0', "sans-serif")};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    border: none;

    &:hover {
      background-color: #D10068;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

`;

function ContactUsForm() {
  const [state, handleSubmit] = useForm('xwkwyyow');

  return (
    <Layout isHideInstagram={true}>
      <SEO title="Contact Us" />
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>
      <PageContentWrapper>
        <PostTitle>Contact Us</PostTitle>
        {state.succeeded && <div>Thank you for contacting us!</div>}
        {!state.succeeded && (
          <form onSubmit={handleSubmit}>
            <FieldContainer>
              <label htmlFor="name">Name</label>
              <input type="text" name="name" maxLength={50} required />
              <ValidationError field="name" prefix="Name" errors={state.errors} />
            </FieldContainer>

            <FieldContainer>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" required />
              <ValidationError field="email" prefix="Email" errors={state.errors} />
            </FieldContainer>

            <FieldContainer>
              <label htmlFor="message">Message</label>
              <textarea name="message" maxLength={3000} required></textarea>
              <ValidationError field="message" prefix="Message" errors={state.errors} />
            </FieldContainer>

            <FieldContainer>
              <div
                className="g-recaptcha"
                data-sitekey="6Lf-rTwaAAAAAPENLZK4EpvCR065amDKNtXV95ql"
              ></div>
            </FieldContainer>

            <FieldContainer>
              <button type="submit" disabled={state.submitting}>
                Submit
              </button>
            </FieldContainer>

            {!!state.errors.length && <div>Some problem occurred while submitting your request. Please try again later.</div>}
          </form>
        )}
      </PageContentWrapper>
    </Layout>
  );
}

export default ContactUsForm;
